import React from 'react';
import './IntroBanner.css';

export default function IntroBanner() {
    return (
        <div>
            <div className='brand'>
                <h3>POSITIVE EDUCATION PROJECT</h3>
            </div>
            <div className='heartBorder'>
                <div className='introTitle'>
                    <h1>Transforming Schools, Empowering Teachers, Inspiring Students</h1>
                </div>
            </div>
        </div>
    );
}
import React from 'react';
import './WhoBanner.css';
import happyCollege from '../media/HappyCollege-1-04.png';

export default function WhoBanner() {
    return (
        <div className='whoBanner'>
            <div className='leftCol' >
            <h2 className='blue'>PEP’s simple and effective solutions that make the difference.</h2>
            <p className='blue'>
            A positive school culture is where students and teachers thrive to create a more joyful, engaged, and well-behaved school environment. PEP offers you: 
            </p>
    
                <ul className='blue'>
                    <li>
                    Practical Workshops: Easy tools you can implement right away.
                    </li>
                    <li>
                    Immediate Impact: See results in student motivation and teacher well-being.                   
                     </li>
                    <li>
                    Fits with Your Curriculum: Seamlessly integrates into existing teaching plans.                
                     </li>
                </ul>
            </div>

            
            <div className='rightCol'>
                <img className='whoImg' src={happyCollege} alt='gratitude cards' />
                <svg className='accent5' width="33vw" height="28vw" viewBox="0 0 333 286" version="1.1"  >
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.3">
                        <g id="Desktop" transform="translate(-904.000000, -251.000000)" fill="#DAB6D6">
                            <path d="M994.457317,251 C1062.49746,251 997.74158,344.100346 1137.1822,344.100346 C1276.62282,344.100346 1289.75657,537 1048.23423,537 C806.711899,537 926.417174,251 994.457317,251 Z" id="Path-3"></path>
                        </g>
                    </g>
                </svg>
                <svg className='accent6' width="25vw" height="40vw" viewBox="0 0 257 408" version="1.1" >
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.3">
                        <g id="Desktop" transform="translate(-635.000000, -367.000000)" fill="#52ADC7">
                            <path d="M877.208794,443 C763.830402,443 807.946726,588.343555 673.977533,510.571046 C540.00834,432.798536 476.235383,762.367698 758.037773,687.985944 C1039.84016,613.604189 990.587186,443 877.208794,443 Z" id="Path-2" transform="translate(763.500000, 571.000000) rotate(-90.000000) translate(-763.500000, -571.000000) "></path>
                        </g>
                    </g>
                </svg>
            </div>
            
        </div>
    )
}
import React from 'react'
import './PurchaseBanner.css';

export default function PurchaseBanner() {
    return (
        <div className='purchaseBanner'>
            <h2 className='purchaseTitle'>Transformative, Tailored Workshops</h2>
            <p className='purchaseSubtitle'>Workshops are tailored for school faculties, parents, students, businesses, youth groups, and conventions, and more!</p>
            <div className='purchaseContainer'>
                <div className='smallLeftCol'>
                    <p className='priceText'>Workshop I</p>
                    <p className='priceText'>PEP Foundations</p>
                    <p className='shippingText'>This hands-on workshop introduces the Positive Education Project (PEP) and provides 3 simple tools to enhance optimism and engagement in your classroom.</p>
                    <ul className='shippingList'>
                    <li className='shippingItem'>
                    Understand Positive Psychology: Explore key concepts like PERMA and Character Strengths.
                    </li>
                    <li className='shippingItem'>
                    See Global Success: Learn how schools worldwide are benefiting from Positive Education.                     </li>
                    <li className='shippingItem'>
                    Apply Practical Tools: Experience mindfulness, savoring, and WWW&W for immediate use in your classroom.                     </li>
                     </ul>
                </div>
                <div className='smallMiddleCol'>
                    <p className='priceText'>Workshop II</p>
                    <p className='priceText'>Energize with Character Strengths</p>
                    <p className='shippingText'>This interactive workshop explores Character Strengths and how to use them to enhance both teaching and student engagement.</p>

                    <ul className='shippingList'>
                    <li className='shippingItem'>
                    Discover Your Strengths: Identify your top character strengths and learn to spot them in others.
                     </li>
                    <li className='shippingItem'>
                    Maximize Engagement: Use character strengths to motivate students and resolve challenges.
                     </li>
                     <li className='shippingItem'>
                     Revitalize Teaching: Reignite your passion for teaching by applying your strengths in the classroom.
                     </li>
                     </ul>
                </div>
                <div className='smallRightCol'>
                    <p className='priceText'>Workshop III</p>
                    <p className='priceText'>Building Grit & Resilience</p>
                    <p className='shippingText'>This engaging workshop focuses on building grit, resilience, and a growth mindset to strengthen the teacher-student relationship and foster lasting success.</p>

                    <ul className='shippingList'>
                    <li className='shippingItem'>
                    Learn the WOOP Method: Use this powerful technique to set and achieve goals.
                     </li>
                    <li className='shippingItem'>
                    Cultivate Resilience: Equip yourself and students to bounce back quickly from setbacks.
                     </li>
                     <li className='shippingItem'>
                     Foster Positivity: Incorporate growth mindset, gratitude, and kindness into classroom culture.
                     </li>
                     </ul>
                </div>
            </div>
            <div className='orderInfo'>
                <h3 className='navy'>Ready to create a school where everyone thrives?<br />
                Email PositiveEducationProject | at | gmail.com</h3>
            </div>
        </div >
    )
}
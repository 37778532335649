import React from 'react';
import './App.css';
import IntroBanner from './components/IntroBanner';
import MainBanner from './components/MainBanner';
import PlacesBanner from './components/PlacesBanner';
import UseBanner from './components/UseBanner';
import PurchaseBanner from './components/PurchaseBanner';
import WhoBanner from './components/WhoBanner';
import Footer from './components/Footer';
import Testimonials from './components/Testimonials';
import AboutJane from './components/AboutJane';

function App() {
  return (

    <div className="App">
      <IntroBanner></IntroBanner>
      <MainBanner></MainBanner>
      <UseBanner></UseBanner>
      <PlacesBanner></PlacesBanner>
      <WhoBanner></WhoBanner>
      <PurchaseBanner></PurchaseBanner>
      <Testimonials></Testimonials>
      <AboutJane></AboutJane>
      <Footer></Footer>
    </div>
  );
}

export default App;

import React from 'react';
import './AboutJane.css';
import jane from '../media/JaneTeaching.png';

export default function AboutJane() {
    return (
        <div className='aboutJane'>
            <div className='leftColJane'>
                <h2 className='blue'>Hello, I'm Jane!</h2>
                <p className='blue'>As a clinical social worker, former teacher, Positive Education Coach, and presenter, I’m passionate about teaching the ‘Science of Happiness’ to help individuals and organizations thrive.</p>
                <p className='blue'>In 2016, I was invited to present Positive Psychology to university students in China and attended the first IPEN Conference in Dallas, connecting with global Positive Education advocates.</p>
                <p className='blue'>I’ve worked with school faculties, students, businesses, youth groups, and conventions, and taught an intensive Positive Psychology course to high schoolers.</p>
                <p className='blue'>Together, we can equip educators, parents, and students with the skills to flourish!</p>
            </div>
            <div className='rightColJane'>
                <img className='aboutImg' src={jane} alt='gratitude cards' />
            </div>
        </div >
    )
}
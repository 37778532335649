import React from 'react';
import './MainBanner.css';
import students from '../media/BoredStudents-1-02.jpg';

export default function MainBanner() {
    return (
        <div className='mainBanner'>
            <div className='leftCol'>
                <h2 className='blue'>Today's school environment faces some serious challenges.</h2>
                <p className='blue'>Students and teachers need more than just academics to succeed. Studies show: </p>
                <ul className='blue'>
                    <li>
                    Teen suicide rates in Utah have more than doubled in just 4 years.
                    </li>
                    <li>
                    Only 50% of students feel engaged in school, dropping to just 33% by 11th grade.
                    </li>
                    <li>
                    75% of students report feeling tired, stressed, or bored at school.
                 </li>
                </ul>
            </div>
            <div className='rightCol'>
                <img className='mainImg' src={students} alt='gratitude cards' />
            </div>
        </div >
    )
}
import React from 'react';
import './PlacesBanner.css';

export default function PlacesBanner() {
    return (
        <div className='placesBanner'>
            <div className='placesTitle'>
                <h2>Why Choose PEP?</h2>
            </div>
            <div className='placesContainer'>
                <div className='places' >
                    <p className='bold'>Boost Engagement</p>
                    <p className='placesText'>Students feel inspired and participate more.</p>
                </div>
                <div className='places'>
                    <p className='bold'>Reduce Stress</p>
                    <p className='placesText'>Emotional well-being creates healthier, happier students</p>
                </div>
                <div className='places'>
                    <p className='bold'>Improve Performance</p>
                    <p className='placesText'>Optimism and resilience boost grades and focus.</p>
                </div>
                <div className='places'>
                    <p className='bold'>Stronger Connection</p>
                    <p className='placesText'>Teachers and students bond over shared strengths.</p>
                </div>
            </div>
        </div>
    )
}
import React from 'react';
import './UseBanner.css';
import teacherStudent from '../media/TeacherStudent-03.png';

export default function UseBanner() {
    return (
        <div className='useBanner'>
            <div className='leftCol'>
                <img className='useImg' src={teacherStudent} alt='gratitude cards' />
            </div>
            <div className='rightCol orange'>
                <h2>There is hope in elevating education and well-being.</h2>
                <p>
                The key to transforming our schools lies in Positive Education, a powerful approach that integrates well-being, character, and academics. Built on the proven principles of Positive Psychology (the ‘Science of Happiness’), Positive Education:
                </p>
                <ul className='orange'>
                     <li>
                     Builds optimism, resilience, and engagement for lasting success.
                    </li>
                    <li>
                    Inspires teachers and students through character strengths.                    </li>
                    <li>
                    Strengthens the connection between teaching and learning.
                    </li>
                </ul>
            </div>
        </div>
    )
}
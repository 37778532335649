import React from 'react'
import './Testimonials.css';

export default function Testimonials() {
    return (
        <div className='carouselDiv'>
            <h2 className='carouselTitle'>What Workshop Participants Are Saying</h2>
            <p className='carouselText'>"Your presentation was one of a series of monthly presentations…Yours last Wednesday has created the biggest positive stir and response by far!” <br></br> -Charter School Administrator</p>
            <p className='carouselText'>"Our homeschool lacked enthusiasm.  The tools that Jane gave us ignited passion for learning in a different way and gave us more motivation to become our best selves and be happier.” <br></br> –Homeschooling Mom</p>
           <p className='carouselText'>“I am frankly ASTONISHED at the response of your presentation!”  -Executive Director of a local Charter School

</p>
        </div>
    )
}
import React from 'react'
import './Footer.css';

export default function Footer() {
    return (
        <div className='footerContainer'>
            <h3 className='footerBrand'>POSITIVE EDUCATION PROJECT</h3>
            <h4 className='footerText navy'>PositiveEducationProject | at | gmail.com</h4>
        </div>
    )
}